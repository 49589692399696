<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Listado de Liquidaciones</v-card-title>
        <DataTableSalary
          :rol="rolUserLogged"
          :state-client="this.$route.params.clientState"
        ></DataTableSalary>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DataTableSalary from './datatables/DataTableSalary.vue'

export default {
  components: {

    DataTableSalary,

  },
  data: () => ({
    rolUserLogged: null,
  }),
  created() {
    this.rolUserLogged = this.$store.getters.usuario.user_rol
  },

}
</script>
